import styled from 'styled-components';
import Section from '@/components/ui/Section';

export const StyledSection = styled(Section)`
  padding-bottom: 0;
`;

export const Inner = styled(Section)`
  background-color: white;
  clip-path: circle(farthest-side at 50% -10000px);

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    clip-path: circle(farthest-side at 50% -4000px);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    clip-path: circle(farthest-side at 50% -2500px);
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: var(--ui-spacings-xl);

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: var(--ui-spacings-lg);
  }
`;
