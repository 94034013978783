import { useContext } from 'react';

import ClientContext from '@/contexts/client.context';

import EnrollButton from '@/app/components/core/EnrollButton';

const CampaignButton = ({ label }) => {
  const { openModal, client } = useContext(ClientContext);

  return (
    <EnrollButton
      id="header_button"
      isActive={client.isActive}
      buttonLocation="uneven-grid"
      openModal={() => openModal({ buttonText: label, buttonLocation: 'uneven-grid' })}
      client={client}
      buttonLabel={label}
      product={client?.product}
      redirectToApp={client?.redirectToApp}
    />
  );
};

export default CampaignButton;
