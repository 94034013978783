import getImgSrcWorkaround from '@/utils/images';
import Image from 'next/image';
import { ArrowDownIcon, Item, ValueContainer, Description, Label } from './styles';
import { MarkdownRichText } from '@/app/components/shared';
import { Heading, RichText } from '@swordhealth/ui-corporate';

const DIRECTION_ENUM = {
  UP: 'UP',
  DOWN: 'DOWN',
};
const ArrowIcon = ({ id, arrow }) => {
  switch (arrow) {
    case DIRECTION_ENUM.UP:
      return (
        <Image
          id={id}
          src="/icons/arrow-top.svg"
          width={32}
          height={32}
          alt="upIcon"
          data-testid="upIcon"
        />
      );
    default:
      return (
        <ArrowDownIcon
          id={id}
          src="/icons/arrow-top.svg"
          width={32}
          height={32}
          alt="downIcon"
          data-testid="downIcon"
        />
      );
  }
};
export default function MetricCard({ id, value, description, arrow, icon, label }) {
  return (
    <Item>
      {label && <Label id={`${id}_label`}>{label}</Label>}
      <ValueContainer>
        {icon?.data && (
          <Image
            id={`${id}_image`}
            src={getImgSrcWorkaround(icon?.url ?? icon?.data?.attributes?.url)}
            alt={icon?.alternativeText ?? icon?.data?.attributes?.alternativeText ?? ''}
            width={32}
            height={32}
          />
        )}
        {arrow && !icon?.data && <ArrowIcon id={`${id}_icon`} arrow={arrow} />}
        <Heading size="2xl" id={`${id}_value`} as="div" gradientBoldText={false}>
          <MarkdownRichText elements={['b', 'strong']}>{value}</MarkdownRichText>
        </Heading>
      </ValueContainer>
      {description && (
        <RichText>
          <Description id={`${id}_description`} $arrow={!!arrow} size="sm" weight="regular">
            <MarkdownRichText elements={['a']}>{description}</MarkdownRichText>
          </Description>
        </RichText>
      )}
    </Item>
  );
}
