import { Container } from '@/components/ui/flexbox';
import MetricCard from './MetricCard';
import { Metrics, TitleWrapper } from './styles';

import Section from '@/components/ui/Section';

import { Heading } from '@swordhealth/ui-corporate';

const ResultsYouCanFeel = ({ content }) => {
  const { title, metrics } = content;

  return (
    <Section>
      <Container>
        {title && (
          <TitleWrapper id="results_title">
            <Heading as="h2" size="2xl">
              {title}
            </Heading>
          </TitleWrapper>
        )}
        <Metrics>
          {metrics.map((m, index) => (
            <MetricCard {...m} id={`results_metric_${m.id}`} key={index} />
          ))}
        </Metrics>
      </Container>
    </Section>
  );
};
export default ResultsYouCanFeel;
