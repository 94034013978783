import { Button, Container, SectionHeader } from '@swordhealth/ui-corporate';
import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';

import { StyledSection, ButtonWrapper, Inner } from './styles';
import UnevenGrid from './UnevenGrid/UnevenGrid';
import CampaignButton from './CampaignButton';

const UnevenGridSection = ({ content, campaign }) => {
  const {
    id,
    titles: { upperLabel, title, description, titleSize },
    cards,
    buttonLabel,
    buttonHref,
  } = content;
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);
  const destinationUrl = buttonHref ?? '/eligibility-check';
  return (
    <StyledSection colored>
      <Inner as="div">
        <Container xAlign={isMobile ? 'center' : 'left'}>
          <SectionHeader
            title={title}
            label={upperLabel}
            description={description ?? ''}
            xAlign={isMobile ? 'center' : 'left'}
            titleSize={titleSize ?? '2xl'}
          />
          <UnevenGrid sectionId={id} cards={cards} />
        </Container>
        <ButtonWrapper>
          {campaign && <CampaignButton label={buttonLabel ?? 'Get Started'} />}
          {!campaign && (
            <Button
              id={`uneven-grid-button-${id}`}
              variant="primary"
              size="lg"
              href={destinationUrl}
              onClick={() => {
                destinationUrl.includes('/eligibility-check')
                  ? trackButtonClicked({
                      action: ACTIONS.REDIRECT_TO_COVERAGE,
                      buttonLocation: 'uneven grid section',
                      buttonText: buttonLabel ?? 'Get Started',
                      buttonType: BUTTON_TYPES.NAVIGATION,
                    })
                  : trackButtonClicked({
                      action: ACTIONS.REDIRECT_TO_FORM,
                      buttonLocation: 'uneven grid section',
                      buttonText: buttonLabel ?? 'Get Started',
                      buttonType: BUTTON_TYPES.NAVIGATION,
                    });
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {buttonLabel ?? 'Get Started'}
            </Button>
          )}
        </ButtonWrapper>
      </Inner>
    </StyledSection>
  );
};

export default UnevenGridSection;
