import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  overflow: clip;
`;
export const Container = styled.div`
  overflow: clip;
  z-index: 2;
  position: relative;
  background-color: ${(props) => props.theme.colors.neutral.default};

  & > div::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    max-width: calc(100vw - 72px);
    margin: auto;
    border-top: 1px solid ${(props) => props.theme.colors.grey.light};

    @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
      max-width: calc(100vw - 72px);
      width: 1264px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.min_sm}px) {
      max-width: calc(100vw - 48px);
    }
  }
`;
